import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import LoadingAnim from "./components/LoadingAnim";
import ErrorDisplay from "./components/ErrorDisplay";
import Layout from "./layout";
import "./styles.css";
import { QueryClient, QueryClientProvider } from "react-query";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      staleTime: Infinity,
      refetchInterval: 1000 * 3600 * 3,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ErrorBoundary boundaryId="root" fallback={<ErrorDisplay />}>
      <Suspense fallback={<LoadingAnim log={"root"} />}>
        <Layout />
      </Suspense>
    </ErrorBoundary>
  </QueryClientProvider>,
  document.getElementById("root")
);
