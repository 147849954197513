import { Route } from "react-router-dom";
import { Switch } from "react-router";
import MainPage from "./MainPage";
import ProductLabelPrintPage from "../product-label";
import PalletLabelPrintPage from "../pallet-label";
import SignatureMaker from "../signature-maker";

const Routes = () => {
  return (
    <Switch>
      <Route path="/signature-maker" component={SignatureMaker} />
      <Route path="/product-label" component={ProductLabelPrintPage} />
      <Route path="/pallet-label" component={PalletLabelPrintPage} />
      <Route path="/**" component={MainPage} />
    </Switch>
  );
};

export default Routes;
