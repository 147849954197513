import { useState } from "react";
import { useForm } from "react-hook-form";
import "./styles.css";

const offices = [
  {
    _id: "6566047bc13be020381b9c22",
    company_name: "Bjelin Sweden AB",
    facebook_url: "https://www.facebook.com/bjelinsweden",
    instagram_url: "https://www.instagram.com/bjelinflooring",
    pinterest_url: "https://www.pinterest.com/bjelin/",
    postal_address: "Kanongatan 86, 254 67 Helsingborg, Sweden",
    contact_email: "support@bjelin.se",
    contact_phone: "020-51 50 10",
    contact_info:
      '<h4>Bjelin Sweden AB</h4>\n<p>Apelv&auml;gen 2<br />263 62 Viken, Sweden<br /><a href="mailto:support@bjelin.se">support@bjelin.se</a><br />020-51 50 10</p>',
    id: "bjelin-se",
    _by: "5e53cc3b8ca80141184b2e42",
    _mby: "5e53cc3b8ca80141184b2e42",
    _modified: 1701252700,
    _created: 1701184635,
    linkedin_url: "https://www.linkedin.com/company/bjelin",
    spotify_url: "https://open.spotify.com/show/0vQvVL0JhforQeFOT3T8uc",
    youtube_url: null,
    website_url: "https://www.bjelin.se",
  },
  {
    _id: "6566047cc13be020381b9c24",
    company_name: "Bjelin Denmark AS",
    facebook_url: "https://www.facebook.com/bjelinofficial",
    instagram_url: "https://www.instagram.com/bjelinflooring",
    pinterest_url: "https://www.pinterest.com/bjelin/",
    postal_address:
      "Herluf Trollesvej 8, 4340 T\u00f8ll\u00f8se - Denmark",
    contact_email: "sales@bjelin.com",
    contact_phone: "+46-771-101 099",
    contact_info:
      '<h4>Bjelin Sweden AB</h4>\n<p>Apelv&auml;gen 2<br />263 62 Viken, Sweden<br /><a href="mailto:sales@bjelin.com">sales@bjelin.com</a><br /><a href="tel:+46771101099">+46-771-101099</a></p>',
    id: "bjelin-dk",
    _by: "5e53cc3b8ca80141184b2e42",
    _mby: "5e53cc3b8ca80141184b2e42",
    _modified: 1701252150,
    _created: 1701184636,
    linkedin_url: "https://www.linkedin.com/company/bjelin",
    spotify_url: null,
    youtube_url: null,
    website_url: "https://dk.bjelin.com",
  },
  {
    _id: "6566047cc13be020381b9c25",
    company_name: "Bjelin Finland Oy",
    facebook_url: "https://www.facebook.com/bjelinfinland/",
    instagram_url: "https://www.instagram.com/bjelin_finland/",
    pinterest_url: "https://www.pinterest.com/bjelin/",
    postal_address:
      "Martinkyl\u00e4ntie 54, 2. krs, 017 20 Vantaa, Suomi",
    contact_email: "myynti@bjelin.com",
    contact_phone: "+358 40 9218055",
    contact_info:
      '<h4>Bjelin Finland Oy</h4>\n<p>Martinkyl&auml;ntie 54, 2. krs<br />017 20 Vanda<br /><a href="mailto:myynti@bjelin.com">myynti@bjelin.com</a><br /><a href="tel:+358409218055">+358 40 9218055</a></p>',
    id: "bjelin-fi",
    _by: "5e53cc3b8ca80141184b2e42",
    _mby: "5e53cc3b8ca80141184b2e42",
    _modified: 1701185280,
    _created: 1701184636,
    linkedin_url: "https://www.linkedin.com/company/bjelin",
    spotify_url: null,
    youtube_url: null,
    website_url: "https://fi.bjelin.com"
  },
  {
    _id: "6566047cc13be020381b9c26",
    company_name: "Bjelin Croatia d.o.o",
    facebook_url: "https://www.facebook.com/bjelinofficial",
    instagram_url: "https://www.instagram.com/bjelinflooring",
    pinterest_url: "https://www.pinterest.com/bjelin/",
    postal_address: "\u017degar VI/39, Ogulin, Croatia",
    contact_email: "hr-sales@bjelin.com",
    contact_phone: " ",
    contact_info: null,
    id: "bjelin-hr",
    _by: "5e53cc3b8ca80141184b2e42",
    _mby: "5e53cc3b8ca80141184b2e42",
    _modified: 1701185296,
    _created: 1701184636,
    linkedin_url: "https://www.linkedin.com/company/bjelin",
    spotify_url: null,
    youtube_url: null,
    website_url: "https://hr.bjelin.com",
  },
  {
    _id: "6566047cc13be020381b9c27",
    company_name: "Bjelin France",
    facebook_url: "https://www.facebook.com/BjelinFrance",
    instagram_url: "https://www.instagram.com/bjelin_france",
    pinterest_url: "https://www.pinterest.com/bjelin/",
    postal_address:
      "400 Avenue Claude Baillet FR-30900 N\u00eemes France",
    contact_email: "support@bjelin.com",
    contact_phone: null,
    contact_info:
      '<h4>Bjelin Sweden AB</h4>\n<p>Apelv&auml;gen 2<br />263 62 Viken, Sweden<br /><a href="mailto:support@bjelin.com">support@bjelin.com</a><br /><a href="tel:+46771101099">+46-771-101099</a></p>',
    id: "bjelin-fr",
    _by: "5e53cc3b8ca80141184b2e42",
    _mby: "5e53cc3b8ca80141184b2e42",
    _modified: 1701185306,
    _created: 1701184636,
    linkedin_url: "https://www.linkedin.com/company/bjelin",
    spotify_url: null,
    youtube_url: null,
    website_url: "https://fr.bjelin.com"
  },
  {
    _id: "6566047cc13be020381b9c28",
    company_name: "Bjelin Germany",
    facebook_url: "https://www.facebook.com/bjelinofficial",
    instagram_url: "https://www.instagram.com/bjelinflooring",
    pinterest_url: "https://www.pinterest.com/bjelin/",
    postal_address: null,
    contact_email: "de-sales@bjelin.com",
    contact_phone: null,
    contact_info:
      '<h4>Bjelin Sweden AB</h4>\n<p>Apelv&auml;gen 2<br />263 62 Viken, Sweden<br /><a href="mailto:support@bjelin.com">support@bjelin.com</a><br /><a href="tel:+46771101099">+46-771-101099</a></p>',
    id: "bjelin-de",
    _by: "5e53cc3b8ca80141184b2e42",
    _mby: "5e53cc3b8ca80141184b2e42",
    _modified: 1701185318,
    _created: 1701184636,
    linkedin_url: "https://www.linkedin.com/company/bjelin",
    spotify_url: null,
    youtube_url: null,
    website_url: "https://de.bjelin.com"
  },
  {
    _id: "6566047cc13be020381b9c29",
    company_name: "Bjelin UK",
    facebook_url: "https://www.facebook.com/BjelinUK",
    instagram_url: "https://www.instagram.com/bjelin_uk/",
    pinterest_url: "https://www.pinterest.com/bjelin/",
    postal_address:
      "Park View Business Centre, Combermere, Whitchurch, Shropshire, SY13 4AL, UK",
    contact_email: "uk-sales@bjelin.com",
    contact_phone: null,
    contact_info:
      '<h4>Bjelin Sweden AB</h4>\n<p>Apelv&auml;gen 2<br />263 62 Viken, Sweden<br /><a href="mailto:support@bjelin.com">support@bjelin.com</a><br /><a href="tel:+46771101099">+46-771-101099</a></p>',
    id: "bjelin-uk",
    _by: "5e53cc3b8ca80141184b2e42",
    _mby: "5e53cc3b8ca80141184b2e42",
    _modified: 1701185328,
    _created: 1701184636,
    linkedin_url: "https://www.linkedin.com/company/bjelin",
    spotify_url: null,
    youtube_url: null,
    website_url: "https://uk.bjelin.com"
  },
  {
    _id: "6566047cc13be020381b9c2a",
    company_name: "Bjelin US",
    facebook_url:
      "https://www.facebook.com/profile.php?id=100090856501913",
    instagram_url: "https://www.instagram.com/bjelin_us/",
    pinterest_url: "https://www.pinterest.com/bjelin/",
    postal_address:
      "12640 Crabapple Rd Suite 210, Milton, Georgia 30004, US",
    contact_email: "us-support@bjelin.com",
    contact_phone: null,
    contact_info:
      '<h4>Bjelin Sweden AB</h4>\n<p>Apelv&auml;gen 2<br />263 62 Viken, Sweden<br /><a href="mailto:support@bjelin.com">support@bjelin.com</a><br /><a href="tel:+46771101099">+46-771-101099</a></p>',
    id: "bjelin-us",
    _by: "5e53cc3b8ca80141184b2e42",
    _mby: "5e53cc3b8ca80141184b2e42",
    _modified: 1701185339,
    _created: 1701184636,
    linkedin_url: "https://www.linkedin.com/company/bjelin",
    spotify_url: null,
    youtube_url: null,
    website_url: "https://us.bjelin.com"
  },
  {
    _id: "6566047cc13be020381b9c2b",
    company_name: "Bjelin Spain",
    facebook_url: "https://www.facebook.com/bjelinofficial",
    instagram_url: "https://www.instagram.com/bjelinflooring",
    pinterest_url: "https://www.pinterest.com/bjelin/",
    postal_address: null,
    contact_email: "support@bjelin.com",
    contact_phone: null,
    contact_info:
      '<h4>Bjelin Sweden AB</h4>\n<p>Apelv&auml;gen 2<br />263 62 Viken, Sweden<br /><a href="mailto:support@bjelin.com">support@bjelin.com</a><br /><a href="tel:+46771101099">+46-771-101099</a></p>',
    id: "bjelin-es",
    _by: "5e53cc3b8ca80141184b2e42",
    _mby: "5e53cc3b8ca80141184b2e42",
    _modified: 1701185351,
    _created: 1701184636,
    linkedin_url: "https://www.linkedin.com/company/bjelin",
    spotify_url: null,
    youtube_url: null,
    website_url: "https://es.bjelin.com"
  },
  {
    _id: "6566047cc13be020381b9c2c",
    company_name: "Bjelin Norway AS",
    facebook_url: "https://www.facebook.com/bjelinnorway/",
    instagram_url: "https://www.instagram.com/bjelin_norway/",
    pinterest_url: "https://www.pinterest.com/bjelin/",
    postal_address: "Arbins gate 2, 0253 Oslo - Norge",
    contact_email: "support@bjelin.com",
    contact_phone: "+47 3322 2300",
    contact_info:
      '<h4>Bjelin Sweden AB</h4>\n<p>Apelv&auml;gen 2<br />263 62 Viken, Sweden<br /><a href="mailto:support@bjelin.com">support@bjelin.com</a><br /><a href="tel:+46771101099">+46-771-101099</a></p>',
    id: "bjelin-no",
    _by: "5e53cc3b8ca80141184b2e42",
    _mby: "5e53cc3b8ca80141184b2e42",
    _modified: 1701252092,
    _created: 1701184636,
    linkedin_url: "https://www.linkedin.com/company/bjelin",
    spotify_url: null,
    youtube_url: null,
    website_url: "https://no.bjelin.com",
  },
  {
    _id: "6566047cc13be020381b9c2d",
    company_name: "Bjelin Italy",
    facebook_url: "https://www.facebook.com/bjelinofficial",
    instagram_url: "https://www.instagram.com/bjelinflooring",
    pinterest_url: "https://www.pinterest.com/bjelin/",
    postal_address: null,
    contact_email: "support@bjelin.com",
    contact_phone: "+46-771-101 099",
    contact_info:
      '<h4>Bjelin Sweden AB</h4>\n<p>Apelv&auml;gen 2<br />263 62 Viken, Sweden<br /><a href="mailto:support@bjelin.com">support@bjelin.com</a><br /><a href="tel:+46771101099">+46-771-101099</a></p>',
    id: "bjelin-it",
    _by: "5e53cc3b8ca80141184b2e42",
    _mby: "5e53cc3b8ca80141184b2e42",
    _modified: 1701185370,
    _created: 1701184636,
    linkedin_url: "https://www.linkedin.com/company/bjelin",
    spotify_url: null,
    youtube_url: null,
    website_url: "https://it.bjelin.com",
  },
  {
    _id: "6566047cc13be020381b9c2e",
    company_name: "Bjelin China",
    facebook_url: "https://www.facebook.com/bjelinofficial",
    instagram_url: "https://www.instagram.com/bjelinflooring",
    pinterest_url: "https://www.pinterest.com/bjelin/",
    postal_address:
      "333 Wanghangdu Road Unit 601 Jingan District, Shanghai 2000042 China",
    contact_email: "support@bjelin.com",
    contact_phone: "+86 21 522 822 62",
    contact_info:
      '<h4>Bjelin China</h4>\n<p>333 Wanghangdu Road<br />Unit 601<br />Jingan District, Shanghai 2000042<br />China<br /><a href="mailto:support@bjelin.com">support@bjelin.com</a><br /><a href="tel:+862152282262">+86 21 522 822 62</a></p>',
    id: "bjelin-cn",
    _by: "5e53cc3b8ca80141184b2e42",
    _mby: "5e53cc3b8ca80141184b2e42",
    _modified: 1701185387,
    _created: 1701184636,
    linkedin_url: "https://www.linkedin.com/company/bjelin",
    spotify_url: null,
    youtube_url: null,
    website_url: "https://cn.bjelin.com",
  },
  {
    _id: "656607d8cc043b26fd659304",
    id: "pervanovo",
    company_name: "Pervanovo Invest AB",
    postal_address: "Apelv\u00e4gen 2, 263 62 Viken, Sweden",
    contact_email: null,
    contact_phone: null,
    facebook_url: null,
    instagram_url: null,
    linkedin_url: null,
    pinterest_url: null,
    spotify_url: null,
    youtube_url: null,
    _mby: "5e53cc3b8ca80141184b2e42",
    _by: "5e53cc3b8ca80141184b2e42",
    _modified: 1701185588,
    _created: 1701185496,
    website_url: "https://pervanovo.se",
  },
  {
    _id: "65660920aedfe72d6f527903",
    id: "valinge-se",
    company_name: "V\u00e4linge Innovation Sweden AB",
    postal_address:
      "Pr\u00e4stav\u00e4gen 513, SE-263 64 Viken, Sweden",
    contact_email: "contact@valinge.se",
    contact_phone: "+46 42 23 78 15",
    website_url: "https://www.valinge.se/",
    facebook_url: null,
    instagram_url: null,
    linkedin_url:
      "https://www.linkedin.com/company/valinge-innovation-ab/",
    pinterest_url: null,
    spotify_url: null,
    youtube_url: "https://www.youtube.com/c/valingeinnovation",
    _mby: "5e53cc3b8ca80141184b2e42",
    _by: "5e53cc3b8ca80141184b2e42",
    _modified: 1701185824,
    _created: 1701185824,
  },
  {
    _id: "65660bbdd32b32471471d3e2",
    id: "bjelin-se-sthlm",
    company_name: "Bjelin Sweden AB",
    postal_address: "Sibyllegatan 38, 114 43 Stockholm - Sweden",
    contact_email: "support@bjelin.com",
    contact_phone: "010-151 68 78",
    website_url: "https://www.bjelin.se",
    facebook_url: "https://www.facebook.com/bjelinsweden",
    instagram_url: "https://www.instagram.com/bjelinflooring",
    linkedin_url: "https://www.linkedin.com/company/bjelin",
    pinterest_url: "https://www.pinterest.com/bjelin/",
    spotify_url: "https://open.spotify.com/show/0vQvVL0JhforQeFOT3T8uc",
    youtube_url: null,
    _mby: "5ffd4e179147fd49e53f6f82",
    _by: "5e53cc3b8ca80141184b2e42",
    _modified: 1705065780,
    _created: 1701186493,
  },
];

function SignatureMaker() {
  const { register, handleSubmit } = useForm();
  const [company, setCompany] = useState("bjelin-se");
  const [firstname, setFirstname] = useState("First name");
  const [surname, setSurname] = useState("Surname");
  const [title, setTitle] = useState("Title");
  const [phone, setPhone] = useState("+46 123 456 789");
  const [mobile, setMobile] = useState("+46 777 777 777");
  const [email, setEmail] = useState("email@bjelin.com");
  const [banner, setBanner] = useState("");

  const curCompany = offices.find((o) => o.id === company);

  const onSubmit = (data) => {
    setCompany(data.company);
    setFirstname(data.firstname);
    setSurname(data.surname);
    setTitle(data.title);
    setPhone(data.phone);
    setMobile(data.mobile);
    setEmail(data.email);
    setBanner(data.banner);
  };

  const onError = (data) => {};

  const copyClick = (e) => {
    var r = document.createRange();
    r.selectNode(document.getElementById("preview"));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  };

  const websiteURL = new URL(curCompany.website_url);
  const website = websiteURL.hostname;
  return (
    <div>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <h1>Pervanovo/Bjelin Signature Maker</h1>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            flex: "1 0 15em",
            maxWidth: "24em",
            padding: "1em",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="form-field">
              <label>Company/Office</label>
              <select name="company" ref={register({})}>
                {offices.map((o) => (
                  <option key={o.id} value={o.id}>
                    {o.id}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-field">
              <label>First name*</label>
              <input
                name="firstname"
                ref={register({ maxLength: 50 })}
              />
            </div>
            <div className="form-field">
              <label>Surname*</label>
              <input name="surname" ref={register({ maxLength: 50 })} />
            </div>
            <div className="form-field">
              <label>Title*</label>
              <input name="title" ref={register({ maxLength: 70 })} />
            </div>
            <div className="form-field">
              <label>Phone*</label>
              <input name="phone" ref={register({ maxLength: 20 })} />
            </div>
            <div className="form-field">
              <label>Mobile</label>
              <input name="mobile" ref={register({ maxLength: 20 })} />
            </div>
            <div className="form-field">
              <label>Email</label>
              <input name="email" ref={register({ maxLength: 100 })} />
            </div>
            <div className="form-field">
              <label>
                Banner image URL
                <br />
                <i style={{ fontSize: "10px" }}>
                  Optional. recommended width: 932px.
                </i>
              </label>
              <input
                name="banner"
                ref={register({ maxLength: 1000 })}
              />
            </div>
            <div className="form-field">
              <input
                type="submit"
                value="Uppdatera"
                style={{ padding: "0.8em" }}
              />
            </div>
          </form>
        </div>

        <div
          style={{
            flex: "1 0 15em",
            maxWidth: "34em",
            padding: "1em",
          }}
        >
          <div
            style={{
              padding: "1em",
            }}
          >
            <h2>Result</h2>
            <p>Copy the content of the box below to your email app</p>
            <button onClick={copyClick}>Copy</button>{" "}
            <span style={{ color: "#999" }}>
              (Does not work properly in Firefox)
            </span>
          </div>
          <div
            className="preview"
            id="preview"
            style={{
              position: "relative",
              backgroundColor: "#ffffff",
              padding: "1em",
              boxShadow: "3px 3px 15px rgba(0,0,0,0.3)",
            }}
          >
            {company.startsWith("bjelin") && (
              <>
                <table
                  rules="none"
                  border="0"
                  cellSpacing="0"
                  cellPadding="0"
                  style={{ border: 0 }}
                  width="512"
                >
                  <tbody>
                    <tr>
                      <td>
                        <table
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                          style={{ border: 0 }}
                        >
                          <tbody>
                            <tr>
                              <td
                                valign="top"
                                style={{ border: 0, paddingRight: 8 }}
                              >
                                <a
                                  href={curCompany.website_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    src="https://toolbox.bjelin.io/bjelin-logo.png"
                                    alt="Bjelin logo"
                                    nosend="1"
                                    border="none"
                                    height="160"
                                    width="160"
                                  />
                                </a>
                              </td>
                              <td
                                valign="top"
                                style={{
                                  border: "0",
                                  paddingLeft: "20px",
                                }}
                              >
                                <table
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          border: "0",
                                          fontFamily:
                                            "Verdana,Helvetica,Arial,sans-serif",
                                          fontSize: "12px",
                                          lineHeight: "18px",
                                          color: "#3e3e3e",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {firstname} {surname}
                                          <br />
                                        </span>
                                        <span
                                          style={{
                                            fontFamily:
                                              "Verdana,Helvetica,Arial,sans-serif",
                                            textTransform: "uppercase",
                                            paddingRight: "7px",
                                            marginRight: "7px",
                                            borderRight:
                                              "1px solid #3e3e3e",
                                          }}
                                        >
                                          {title}
                                        </span>
                                        {curCompany.company_name}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                          style={{
                                            border: "0",
                                            paddingBottom: "10px",
                                            fontFamily:
                                                "Verdana,Helvetica,Arial,sans-serif",
                                            fontSize: "12px",
                                            lineHeight: "18px",
                                            color: "#3e3e3e",
                                          }}
                                      >
                                        <span
                                            style={{
                                              whiteSpace: "nowrap",
                                              display: "inline-block",
                                              color: "#595959",
                                            }}
                                        >
                                          {phone}
                                        </span>
                                        {mobile && (
                                            <span
                                                style={{
                                                  whiteSpace: "nowrap",
                                                  display: "inline-block",
                                                  color: "#595959",
                                                }}
                                            >
                                            {", "}
                                              {mobile}
                                          </span>
                                        )}
                                        {email && (
                                            <>
                                              <br />
                                              <span>
                                                <a
                                                href={`mailto:${email}`}
                                                style={{
                                                  color: "#3e3e3e",
                                                  textDecoration: "none",
                                                }}
                                                >
                                                {email}
                                                </a>
                                              </span>
                                            </>
                                        )}
                                        <br />
                                        <br />
                                        <span>
                                          {curCompany.postal_address}
                                        </span>
                                        <br />
                                        <a
                                          href={curCompany.website_url}
                                          target="_blank"
                                          rel="noreferrer"
                                          style={{
                                            fontFamily:
                                              "Verdana,Helvetica,Arial,sans-serif",
                                            color: "#3e3e3e",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          {website}
                                        </a>
                                        {company.startsWith(
                                          "bjelin"
                                        ) && (curCompany.linkedin_url || curCompany.facebook_url || curCompany.instagram_url || curCompany.spotify_url) && (
                                          <>
                                            <br />
                                            <br />
                                            <span
                                              style={{
                                                display: "flex",
                                              }}
                                            >
                                              {curCompany.linkedin_url && (
                                                <a
                                                  href={
                                                    curCompany.linkedin_url
                                                  }
                                                  rel="noreferrer"
                                                  style={{
                                                    boxSizing:
                                                      "content-box", //Gmail doesn't support other box models
                                                    display:
                                                      "inline-block",
                                                    width: "16px",
                                                    height: "16px",
                                                    lineHeight: "16px",
                                                    padding: "5px",
                                                    marginRight: "8px",
                                                    color: "#3e3e3e",
                                                    textDecoration:
                                                      "none",
                                                    border:
                                                      "1px solid #ddd",
                                                    borderRadius: "50%",
                                                  }}
                                                >
                                                  <img
                                                    src="https://toolbox.bjelin.io/icons/linkedin-in.png"
                                                    alt="linkedin"
                                                    width="16"
                                                    height="16"
                                                  />
                                                </a>
                                              )}
                                              {curCompany.facebook_url && (
                                                <a
                                                  href={
                                                    curCompany.facebook_url
                                                  }
                                                  rel="noreferrer"
                                                  style={{
                                                    boxSizing:
                                                      "content-box", //Gmail doesn't support other box models
                                                    display:
                                                      "inline-block",
                                                    width: "16px",
                                                    height: "16px",
                                                    lineHeight: "16px",
                                                    padding: "5px",
                                                    marginRight: "8px",
                                                    color: "#3e3e3e",
                                                    textDecoration:
                                                      "none",
                                                    border:
                                                      "1px solid #ddd",
                                                    borderRadius: "50%",
                                                  }}
                                                >
                                                  <img
                                                    src="https://toolbox.bjelin.io/icons/facebook-f.png"
                                                    alt="facebook"
                                                    width="16"
                                                    height="16"
                                                  />
                                                </a>
                                              )}
                                              {curCompany.instagram_url && (
                                                <a
                                                  href={
                                                    curCompany.instagram_url
                                                  }
                                                  rel="noreferrer"
                                                  style={{
                                                    boxSizing:
                                                      "content-box", //Gmail doesn't support other box models
                                                    display:
                                                      "inline-block",
                                                    width: "16px",
                                                    height: "16px",
                                                    lineHeight: "16px",
                                                    padding: "5px",
                                                    marginRight: "8px",
                                                    color: "#3e3e3e",
                                                    textDecoration:
                                                      "none",
                                                    border:
                                                      "1px solid #ddd",
                                                    borderRadius: "50%",
                                                  }}
                                                >
                                                  <img
                                                    src="https://toolbox.bjelin.io/icons/instagram.png"
                                                    alt="instagram"
                                                    width="16"
                                                    height="16"
                                                  />
                                                </a>
                                              )}
                                              {curCompany.spotify_url && (
                                                <a
                                                  href={
                                                    curCompany.spotify_url
                                                  }
                                                  rel="noreferrer"
                                                  style={{
                                                    boxSizing:
                                                      "content-box", //Gmail doesn't support other box models
                                                    display:
                                                      "inline-block",
                                                    width: "16px",
                                                    height: "16px",
                                                    lineHeight: "16px",
                                                    padding: "5px",
                                                    marginRight: "8px",
                                                    color: "#3e3e3e",
                                                    textDecoration:
                                                      "none",
                                                    border:
                                                      "1px solid #ddd",
                                                    borderRadius: "50%",
                                                  }}
                                                >
                                                  <img
                                                    src="https://toolbox.bjelin.io/icons/spotify.png"
                                                    alt="spotify"
                                                    width="16"
                                                    height="16"
                                                  />
                                                </a>
                                              )}
                                            </span>
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        style={{
                          padding: "0",
                          paddingTop: "2px",
                          border: "0",
                          fontFamily: "Georgia,serif",
                          color: "#3e3e3e",
                          fontSize: "10px",
                          lineHeight: "14px",
                          wordBreak: "break-word",
                        }}
                      >
                        {banner && (
                          <img
                            alt=""
                            src={banner}
                            style={{ width: "100%" }}
                          />
                        )}
                        <br />
                        <i>
                          We process your personal data in accordance
                          with the General Data Protection Regulation
                          "GDPR". For more information about this,
                          request for rectification or erasure etc.,
                          visit our website:{" "}
                          <a
                            href="https://www.bjelin.com/privacy"
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "#3e3e3e" }}
                          >
                            www.bjelin.com/privacy
                          </a>
                          . This e-mail and any attachments are intended
                          only for the addressees and contains
                          confidential information. If you have received
                          this e-mail in error, please notify us
                          immediately and delete the message from your
                          system.
                        </i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
            {!company.startsWith("bjelin") && (
              <>
                <table
                  rules="none"
                  border="0"
                  cellSpacing="0"
                  cellPadding="0"
                  style={{ border: 0 }}
                  width="466"
                >
                  <tbody>
                    <tr>
                      <td>
                        <table
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                          style={{ border: 0 }}
                        >
                          <tbody>
                            <tr>
                              <td
                                valign="top"
                                style={{ border: 0, paddingRight: 8 }}
                              >
                                {company.startsWith("valinge") ? (
                                  <a
                                    href="https://valinge.se"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: "rgb(204,0,0)" }}
                                  >
                                    <img
                                      src="https://toolbox.bjelin.io/valinge-logo.png"
                                      border="none"
                                      height="90"
                                      width="90"
                                      alt="Välinge logo"
                                    />
                                  </a>
                                ) : (
                                  <a
                                    href="https://pervanovo.se"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: "rgb(204,0,0)" }}
                                  >
                                    <img
                                      src="https://toolbox.bjelin.io/pervanovo-logo.png"
                                      border="none"
                                      height="90"
                                      width="90"
                                      alt="Pervanovo logo"
                                    />
                                  </a>
                                )}
                              </td>
                              <td
                                valign="top"
                                style={{
                                  borderWidth: "0px 0px 0px 1px",
                                  borderTopStyle: "initial",
                                  borderRightStyle: "initial",
                                  borderBottomStyle: "initial",
                                  borderTopColor: "initial",
                                  borderRightColor: "initial",
                                  borderBottomColor: "initial",
                                  borderLeftStyle: "solid",
                                  borderLeftColor: "rgb(204,0,0)",
                                  paddingLeft: "5px",
                                }}
                              >
                                <table
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        nowrap=""
                                        style={{
                                          border: "0px",
                                          paddingBottom: "10px",
                                          lineHeight: "21p",
                                        }}
                                      >
                                        <font color="#666666">
                                          <span
                                            style={{
                                              fontFamily:
                                                "Helvetica,Arial,sans-serif",
                                              lineHeight: "21px",
                                              fontSize: "15px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {firstname} {surname}
                                            <br />
                                          </span>
                                          <span
                                            style={{
                                              fontFamily:
                                                "Helvetica,Arial,sans-serif",
                                              fontSize: "13px",
                                              lineHeight: "21px",
                                            }}
                                          >
                                            {title}&nbsp;
                                          </span>
                                          <span
                                            style={{
                                              fontFamily:
                                                "Helvetica,Arial,sans-serif",
                                              lineHeight: "21px",
                                              fontSize: "13px",
                                            }}
                                          >
                                            |&nbsp;
                                            {curCompany.company_name}
                                          </span>
                                        </font>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          border: "0px",
                                          paddingBottom: "10px",
                                          fontSize: "12px",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            whiteSpace: "nowrap",
                                            fontFamily:
                                              "Helvetica,Arial,sans-serif",
                                            display: "inline-block",
                                            lineHeight: "18px",
                                          }}
                                        >
                                          <img
                                            valign="bottom"
                                            src="https://toolbox.bjelin.io/icons/phone.png"
                                            border="none"
                                            height="16"
                                            width="16"
                                            alt="phone"
                                          />
                                          &nbsp;{phone}
                                        </span>
                                        {mobile && (
                                          <span
                                            style={{
                                              whiteSpace: "nowrap",
                                              fontFamily:
                                                "Helvetica,Arial,sans-serif",
                                              display: "inline-block",
                                              lineHeight: "18px",
                                              marginLeft: "12px",
                                            }}
                                          >
                                            <img
                                              valign="bottom"
                                              src="https://toolbox.bjelin.io/icons/mobile.png"
                                              border="none"
                                              height="16"
                                              width="16"
                                              alt="mobile"
                                            />
                                            &nbsp;{mobile}
                                          </span>
                                        )}
                                         <br />
                                        <span
                                          style={{
                                            whiteSpace: "nowrap",
                                            fontFamily:
                                              "Helvetica,Arial,sans-serif",
                                            display: "inline-block",
                                            lineHeight: "18px",
                                          }}
                                        >
                                          <img
                                            valign="bottom"
                                            src="https://toolbox.bjelin.io/icons/mail.png"
                                            border="none"
                                            height="16"
                                            width="16"
                                            alt="e-mail"
                                          />
                                          &nbsp;
                                          <a
                                            href={`mailto:${email}`}
                                            style={{
                                              color: "rgb(204,0,0)",
                                            }}
                                          >
                                            {email}
                                          </a>
                                        </span>
                                        &nbsp; 
                                        <br />
                                        <span
                                          style={{
                                            whiteSpace: "nowrap",
                                            fontFamily:
                                              "Helvetica,Arial,sans-serif",
                                            display: "inline-block",
                                            lineHeight: "18px",
                                          }}
                                        >
                                          <img
                                            valign="bottom"
                                            src="https://toolbox.bjelin.io/icons/website.png"
                                            border="none"
                                            height="16"
                                            width="16"
                                            alt="website"
                                          />
                                          &nbsp;
                                          {company.startsWith(
                                            "valinge"
                                          ) ? (
                                            <a
                                              href="https://valinge.se"
                                              target="_blank"
                                              rel="noreferrer"
                                              style={{
                                                color: "rgb(204,0,0)",
                                              }}
                                            >
                                              www.valinge.se
                                            </a>
                                          ) : (
                                            <a
                                              href="https://pervanovo.se"
                                              target="_blank"
                                              rel="noreferrer"
                                              style={{
                                                color: "rgb(204,0,0)",
                                              }}
                                            >
                                              www.pervanovo.se
                                            </a>
                                          )}
                                        </span>
                                        <br />
                                        <span
                                          style={{
                                            fontFamily:
                                              "Helvetica,Arial,sans-serif",
                                            display: "inline-block",
                                            lineHeight: "18px",
                                          }}
                                        >
                                          <img
                                            valign="bottom"
                                            src="https://toolbox.bjelin.io/icons/address.png"
                                            border="none"
                                            height="16"
                                            width="16"
                                            alt="address"
                                          />
                                          <span>
                                            &nbsp;
                                            {curCompany.postal_address}
                                          </span>
                                        </span>
                                        <br />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: "5px",
                          border: "0px",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        style={{
                          padding: "2px 0px 0px",
                          border: "0px",
                          wordBreak: "break-word",
                          fontFamily: "Helvetica,Arial,sans-serif",
                          lineHeight: "10px",
                          fontSize: "9px",
                        }}
                      >
                        {banner && (
                          <img
                            alt=""
                            src={banner}
                            style={{ width: "100%" }}
                          />
                        )}
                        <i>
                          <font color="#666666">
                            We process your personal data in accordance
                            with the General Data Protection Regulation
                            "GDPR". For more information about this,
                            request for rectification or erasure etc.,
                            visit our website:{" "}
                            {company.startsWith("valinge") ? (
                              <a
                                href="http://www.valinge.se/about/privacy"
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: "rgb(204,0,0)" }}
                              >
                                www.valinge.se/about/privacy
                              </a>
                            ) : (
                              <a
                                href="https://pervanovo.se/privacy/"
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: "rgb(204,0,0)" }}
                              >
                                www.pervanovo.se/privacy
                              </a>
                            )}
                            . This e-mail and any attachments are
                            intended only for the addressees and
                            contains confidential information. If you
                            have received this e-mail in error, please
                            notify us immediately and delete the message
                            from your system.
                          </font>
                        </i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignatureMaker;
