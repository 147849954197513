import React, { Suspense } from "react";
import Page from "../layout/Page";
import PalletLabelPrint from "./PalletLabelPrint";

const PalletLabelPrintPage = ({ location, history }) => {
  return (
    <Page
      isInternal
      title={"Label printing"}
      name={"label-print"}
      key={"label-print"}
      location={location}
      history={history}
    >
      <div style={{ padding: "12rem 2rem" }}>
        <Suspense fallback={null}>
          <PalletLabelPrint />
        </Suspense>
      </div>
    </Page>
  );
};

export default PalletLabelPrintPage;
