import React from "react";
import { Link } from "react-router-dom";
import Page from "./Page";
import "./mainPage.css";

const MainPage = ({ location, history }) => {
  return (
    <Page
      isInternal
      title={"Main"}
      name={"main"}
      key={"main"}
      location={location}
      history={history}
    >
      <div className="wrapper">
        <img
          src="https://storage.pervanovo.se/emailimages/bjelin/v2/bjelin.png"
          alt="Bjelin logo"
          nosend="1"
          border="none"
          height="140"
          width="140"
        />
        <h1>BJELIN toolbox</h1>
        <ul>
          <li>
            <Link to={"/signature-maker"}>Signature maker</Link>
          </li>
          <li>
            <Link to={"/pallet-label"}>Pallet label</Link>
          </li>
          {/*<li>
            <Link to={"/product-label"}>Product label</Link>
          </li>*/}
        </ul>
      </div>
    </Page>
  );
};

export default MainPage;
