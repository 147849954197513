import React, { useEffect } from "react";

const LoadingAnim = ({
  color = "white",
  filled = false,
  style = {},
  log = ""
}) => {
  console.log("Suspense: " + log);

  useEffect(() => {
    if (window.bjelinLoadingAnim) window.bjelinLoadingAnim.show();
    return () => {
      console.log("Suspense end: " + log);
      if (window.bjelinLoadingAnim) window.bjelinLoadingAnim.hide();
    };
  });

  const isSwedish = /^sv\b/.test(navigator.language);

  return (
    <div
      style={{
        position: "absolute",
        zIndex: 1000,
        top: 0,
        bottom: "30vh",
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: "24px",
        fontWeight: 700,
        color: "#fff",
        textShadow: "0 0 20px rgba(0,0,0,0.8)",
        opacity: 0,
        animation: "2.5s ease-in-out 1s infinite alternate both fadein"
      }}
    >
      {isSwedish ? "Laddar" : "Loading"}
    </div>
  );
};

export default LoadingAnim;
