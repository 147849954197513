import React, { useCallback, useEffect, useRef, useState } from "react";
import Frame, { useFrame } from "react-frame-component";
import { useMediaQuery } from "react-responsive";

const stylesheet = `
* {
    box-sizing: border-box;
}
html {
  width: 165mm;
  height: 61.2mm;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 2.5mm;
}
body {
  margin: 0;
  padding: 0;
}
.wrapper {
  width: 160mm;
  height: 60mm;
  margin: 1.2mm 2.5mm 0 2.5mm;
  position: relative;
}
.main-cell {
  position: absolute;
  top: 1.5mm;
  left: 1.5mm;
  width: 128.5mm;
  height: 27mm;
  /*background: #e9e9e9;*/
  padding: 1.5mm 3mm;
}
.logo-cell {
  position: absolute;
  top: 1.5mm;
  right: 1.5mm;
  width: 27mm;
  height: 27mm;
  /*background: #c3c3c3;*/
}
.qr-cell {
  position: absolute;
  bottom: 1.5mm;
  left: 1.5mm;
  width: 28.5mm;
  height: 28.5mm;
  padding-top: 1.5mm;
  font-size: 0.9em;
  text-align: center;
}
.cat-cell {
  position: absolute;
  bottom: 1.5mm;
  left: 30.5mm;
  width: 52mm;
  height: 28.5mm;
  padding: 1.5mm 3mm;
}
.info-cell {
  position: absolute;
  bottom: 1.5mm;
  right: 1.5mm;
  width: 74mm;
  height: 28.5mm;
  /*background: #e0d9cd;*/
  padding: 1.5mm 3mm;
}
.info-cell p {
  margin-bottom: 0.5em;
}
h2 {
  font-size: 1.8em;
  letter-spacing: -0.05em;
  padding: 0;
  margin: 0;
}
p {
  padding: 0;
  margin: 0;
}
hr {
  height: 1px;
  border: 0;
  border-bottom: 0.5pt solid #000;
}
.specs-list {
  display: flex;
  gap: 4mm;
  font-size: 1.2em;
}
.tagline {
  display: flex;
  margin-top: 1.5mm;
}
.tagline .spec-wrapper {
  flex: 0 0 14mm;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}
.tagline .spec-wrapper .spec-icon {
  width: 8.5mm;
}
.tagline .spec-wrapper .spec-icon-label {
  text-align: center;
  font-size: 0.75em;
  line-height: 1.1em;
}

`;

const ProductLabel = () => {
  // Hook returns iframe's window and document instances from Frame context
  const { window } = useFrame();

  useEffect(() => {
    window?.focus();
    window?.print();
  }, [window]);

  return <div className="wrapper">TEST</div>;
};

const ProductLabelPrint = ({ sku, showPreview = false, apiKey }) => {
  const [doPrint, setDoPrint] = useState(false);
  const isWide = useMediaQuery({ minWidth: 1024 });

  const skuInput = useRef();
  const [artId, setArtId] = useState(sku);

  const onClick = useCallback(() => {
    if (!skuInput.current?.value) {
      alert("Please enter a SKU");
      return;
    }
    setArtId(skuInput.current?.value);
    setDoPrint(true);
    setTimeout(() => {
      setDoPrint(false);
      skuInput.current.focus();
    }, 2000);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: isWide ? "row" : "column",
        gap: "1em"
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.7em"
        }}
      >
        <label
          htmlFor="sku-input"
          style={{
            fontSize: "1.4em"
          }}
        >
          Sku:
        </label>
        <input
          type="text"
          id="sku-input"
          defaultValue={sku}
          ref={skuInput}
          style={{ fontSize: "2em", width: "5em" }}
          autoFocus
          onKeyDown={e => {
            switch (e.key) {
              case "Enter":
                onClick();
                e.preventDefault();
                break;
              default:
            }
          }}
        />
      </div>
      <button disabled={doPrint} onClick={onClick}>
        Print
      </button>
      {doPrint && (
        <Frame
          head={<style>{stylesheet}</style>}
          style={{
            width: showPreview ? "fit-content" : 0,
            height: showPreview ? "fit-content" : 0
          }}
        >
          <ProductLabel artId={artId} />
        </Frame>
      )}
    </div>
  );
};
export default ProductLabelPrint;
