import React from "react";
import classnames from "classnames";
import { motion } from "framer-motion";

export const PageTransitionEndContext = React.createContext(null);

const Page = ({ name, children }) => {
  const motionVariants = {
    initial: {
      opacity: 0,
      zIndex: 1
    },
    in: {
      opacity: 1,
      zIndex: 1,
      transition: {
        type: "tween",
        ease: "anticipate",
        duration: 2.3
      },
      transitionEnd: {
        opacity: 1,
        zIndex: 0
      }
    },
    out: {
      opacity: 0,
      zIndex: -1,
      transition: {
        type: "tween",
        ease: "anticipate",
        duration: 1
      }
    }
  };

  const transitionConfig = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5
  };

  return (
    <motion.div
      className={classnames("page", name)}
      initial="initial"
      animate="in"
      exit="out"
      variants={motionVariants}
      transition={transitionConfig}
    >
      {children}
    </motion.div>
  );
};

export default Page;
