import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Frame, { useFrame } from "react-frame-component";
import { useQuery } from "react-query";
import { useMediaQuery } from "react-responsive";

const stylesheet = `
* {
    box-sizing: border-box;
}
html {
  width: 107mm;
  height: 230mm;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 5mm;
}
body {
  width: 107mm;
  height: 230mm;
  margin: 0;
  padding: 5mm 2.5mm;
}
.wrapper {
  width: 220mm;
  height: 102mm;
  margin: 0;
  transform: rotate(90deg) translateX(59mm) translateY(59mm);
  transform-origin: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 16mm;
  border: 1pt solid #000;
  padding-top: 2mm;
}
.cell label {
  position: absolute;
  top: 1mm;
  left: 3mm;
  font-size: 5mm;
}
.cell .fsc {
  position: absolute;
  top: 1mm;
  right: 3mm;
  font-size: 5mm;
}
.cell .prod-name {
  position: absolute;
  bottom: 1mm;
  left: 3mm;
  right: 3mm;
  font-size: 10mm;
  text-align: center;
}
.cell-sku { grid-area: 1 / 1 / 4 / 4; font-size: 45mm; padding-bottom: 5mm;}
.cell-nbr { grid-area: 4 / 1 / 5 / 2; }
.cell-sup { grid-area: 4 / 2 / 5 / 3; }
.cell-pkgs { grid-area: 4 / 3 / 5 / 4; }

`;

const PalletLabel = ({ sku, nbr, sup, pkgs, product }) => {
  // Hook returns iframe's window and document instances from Frame context
  const { window } = useFrame();

  useEffect(() => {
    window?.focus();
    window?.print();
  }, [window]);

  return (
    <div className="wrapper">
      <div className="cell cell-sku">
        <label>SKU#</label>
        {sku}
        <div className="prod-name">{product ? product.name : ""}</div>
        {product?.specsIndexed?.FSC?.value && (
          <div className="fsc">{product?.specsIndexed?.FSC?.value}</div>
        )}
      </div>
      <div className="cell cell-nbr">
        <label>Purchase order #</label>
        {nbr}
      </div>
      <div className="cell cell-sup">
        <label>Supplier</label>
        {sup}
      </div>
      <div className="cell cell-pkgs">
        <label>Pkgs / pallet</label>
        {pkgs}
      </div>
    </div>
  );
};

const baseURL = "https://www.bjelin.se/api/";

export function unwrapSpecifications(specifications) {
  if (Array.isArray(specifications)) {
    const specsIndexed = specifications.reduce((map, [field, rawValue]) => {
      const match = rawValue.match(/^([0-9,.± ]+)\s([^0-9,.±]+)$/);
      if (match) {
        const [, numValue, unit] = match;
        map[field] = { value: numValue, unit };
      } else {
        map[field] = { value: rawValue };
      }
      return map;
    }, {});
    //console.log("unwrapSpecifications", specsIndexed);
    return specsIndexed;
  }
  return {};
}

export function unwrapField(field) {
  return (data) => data[field];
}

export function enhanceProduct(entry) {
  entry.specsIndexed = unwrapSpecifications(entry.specs);
  entry.specifications = entry.specs ? [...entry.specs] : [];
  delete entry.specs;

  if (Array.isArray(entry.tags)) {
    entry.tags = entry.tags.map((tag) => tag.name || tag.display);
  } else {
    entry.tags = [];
  }
  return entry;
}

function loadLabelProduct(sku, lang, key) {
  const params = {
    lang,
    key,
  };
  return new Promise((resolve, reject) => {
    if (sku) {
      axios
        .create({
          baseURL,
        })
        .get("/label_product/" + sku, { params })
        .then(({ data: product }) => resolve(enhanceProduct(product)))
        .catch(reject);
    } else {
      resolve(undefined);
    }
  });
}

const PalletLabelPrint = () => {
  const [doPrint, setDoPrint] = useState(false);
  const isWide = useMediaQuery({ minWidth: 1024 });

  const skuInput = useRef();
  const nbrInput = useRef();
  const supInput = useRef();
  const pkgsInput = useRef();
  const [sku, setSku] = useState();
  const [nbr, setNbr] = useState();
  const [sup, setSup] = useState();
  const [pkgs, setPkgs] = useState();

  const apiKey = "golvet-är-rummets-största-möbel"; // process.env.LABEL_PRODUCT_API_KEY;

  const { data: product } = useQuery(
    ["labelProduct", sku, "sv", apiKey],
    async () => await loadLabelProduct(sku, "sv", apiKey),
    { retry: false, useErrorBoundary: false }
  );

  //console.log("product", product);

  const onClick = useCallback(() => {
    if (!skuInput.current?.value) {
      alert("Please enter a SKU");
      return;
    }
    setSku(skuInput.current?.value);
    setNbr(nbrInput.current?.value);
    setSup(supInput.current?.value);
    setPkgs(pkgsInput.current?.value);
    setDoPrint(true);
    setTimeout(() => {
      setDoPrint(false);
      skuInput.current.focus();
    }, 2000);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: isWide ? "row" : "column",
        gap: "1em",
        fontSize: "2vmax",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.7em",
        }}
      >
        <label htmlFor="sku-input">SKU#:</label>
        <input
          type="text"
          id="sku-input"
          name="SKU"
          ref={skuInput}
          style={{ fontSize: "2em", width: "4em" }}
          autoFocus
          onKeyDown={(e) => {
            switch (e.key) {
              case "Enter":
                onClick();
                e.preventDefault();
                break;
              default:
            }
          }}
        />
        <div>{product ? product.name : ""}</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.7em",
        }}
      >
        <label htmlFor="nbr-input">Purchase order #:</label>
        <input
          type="text"
          id="nbr-input"
          name="Purchase order"
          ref={nbrInput}
          style={{ fontSize: "2em", width: "5em" }}
          onKeyDown={(e) => {
            switch (e.key) {
              case "Enter":
                onClick();
                e.preventDefault();
                break;
              default:
            }
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.7em",
        }}
      >
        <label htmlFor="sup-input">Supplier:</label>
        <input
          type="text"
          id="sup-input"
          name="Supplier"
          ref={supInput}
          style={{ fontSize: "2em", width: "5em" }}
          onKeyDown={(e) => {
            switch (e.key) {
              case "Enter":
                onClick();
                e.preventDefault();
                break;
              default:
            }
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.7em",
        }}
      >
        <label htmlFor="pkgs-input">Pkgs / pallet:</label>
        <input
          type="number"
          id="pkgs-input"
          name="pkgs per pallet"
          ref={pkgsInput}
          style={{ fontSize: "2em", width: "3em" }}
          onKeyDown={(e) => {
            switch (e.key) {
              case "Enter":
                onClick();
                e.preventDefault();
                break;
              default:
            }
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.7em",
          paddingTop: "1.4em",
        }}
      >
        <button
          disabled={doPrint}
          onClick={onClick}
          style={{
            fontSize: "2vmax",
            padding: "1em",
            backgroundColor: "var(--bjelin-green)",
            color: "#fff",
          }}
        >
          Print
        </button>
      </div>
      {doPrint && (
        <Frame
          head={<style>{stylesheet}</style>}
          style={{
            width: 0,
            height: 0,
          }}
        >
          <PalletLabel
            sku={sku}
            nbr={nbr}
            sup={sup}
            pkgs={pkgs}
            product={product}
          />
        </Frame>
      )}
    </div>
  );
};
export default PalletLabelPrint;
